import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { untilDestroyed } from '@ngneat/until-destroy';

import { MessageCenterAuthService, MessageCenterService } from '@app/pages/message-center/services';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { ChatsLookupField, ConversationType, UserChat } from '@app/pages/message-center/models/chat.model';
import { ItemsComponent, MergeStrategy } from '@app/core/components';
import { WsConnectionModule, WsData, WsEvent } from '@app/core/services/websocket/ws.models';
import { ModuleSubscriptionParams } from '@app/core/services';
import { getRemainingScrollDistanceToBottom } from '@app/shared/helper';
import { PaginationIfc } from '@app/shared/interfaces/pagination.class';
import { filterWsEvents } from '@app/core/services/websocket/rxjs-ws-operators';
import { Message } from '@app/pages/message-center/models/message.model';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, filter } from 'rxjs/operators';
import { ISOTimeToDaysHoursMinutes } from '@app/pages/message-center/shared/helper';
import { ConfirmationDialogComponent } from "@app/shared/popups/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-chats-list',
  templateUrl: './chats-list.component.html',
  styleUrls: ['./chats-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatsListComponent extends ItemsComponent<UserChat> implements OnInit {
  @Input() activeChatId?: number;

  @Output() openChat = new EventEmitter<UserChat>();

  filtrationForm: FormGroup;
  override pagination: PaginationIfc = new PaginationIfc(15);

  private mcProfile = this.messageCenterAuthService.getProfile();
  protected override responseMergeStrategy = MergeStrategy.Append;
  protected override realtimeModules: ModuleSubscriptionParams[] = [
    { module: WsConnectionModule.listOfChats, user_id: this.mcProfile?.id }
  ];
  protected deleteItemRealtimeEvents = [WsEvent.deleteChat];
  protected updateItemRealtimeEvents = [WsEvent.chatListUpdating];
  protected createItemRealtimeEvents = [WsEvent.newChat];
  protected override defaultEventMapper = (data: WsData<any>) => data.payload;

  readonly ConversationType = ConversationType;
  readonly chatTypes = [
    { value: ChatsLookupField.All, title: 'messageCenter.chatType_all' },
    { value: ChatsLookupField.Staff, title: 'messageCenter.chatType_staff' },
    { value: ChatsLookupField.Caregiver, title: 'messageCenter.chatType_caregiver' },
    { value: ChatsLookupField.Chats, title: 'messageCenter.chatType_chats' }
  ];

  get isLoadingWithReplace(): boolean {
    return this.isLoading && this.responseMergeStrategy === MergeStrategy.Replace;
  }

  constructor(
    private messageCenterService: MessageCenterService,
    private messageCenterAuthService: MessageCenterAuthService,
    private dialog: MatDialog,
    private translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    this.filtrationForm = new FormGroup({
      search: new FormControl(''),
      lookup_field: new FormControl(this.chatTypes[0].value),
    });

    super.ngOnInit();

    this.filtrationForm.valueChanges
      .pipe(debounceTime(500), untilDestroyed(this))
      .subscribe(() => {
        this.responseMergeStrategy = MergeStrategy.Replace;
        this.items = [];
        this.pagination.offsetChanged(0);
        this.loadItems();
      });
  }

  protected override getItems(): Observable<PaginatedResponse<UserChat>> {
    return this.messageCenterService.getUsersChats({
      offset: this.items.length,
      limit: this.pagination.limit,
      ...this.filtrationForm.value,
    });
  }

  protected override subscribeToRealtime() {
    super.subscribeToRealtime();
    this.realtimeService.messages$
      .pipe(filterWsEvents<Message>([WsEvent.newMessage]), untilDestroyed(this))
      .subscribe((payload) => {
        const chat: UserChat = this.items.find(item => item.id === payload.chat);
        const isOwnMessage = payload.sender.id === this.mcProfile?.id;

        if (!payload.reply_to && chat) {
          const alreadyHasMessage = chat.unread_messages_ids.some(id => id === payload.id);

          this.updateItem({
            id: payload.chat,
            last_message: { ...payload, has_files: !!payload.files.length },
            unread_messages_ids: (isOwnMessage || alreadyHasMessage) ? chat.unread_messages_ids : [...chat.unread_messages_ids, payload.id]
          });
        }
      });

    this.realtimeService.messages$
      .pipe(filterWsEvents<{ id: number, chat: number }>([WsEvent.userViewedMessage]), untilDestroyed(this))
      .subscribe((payload) => {
        const chat: UserChat = this.items.find(item => item.id === payload.chat);
        this.updateItem({
          id: payload.chat,
          unread_messages_ids: (chat?.unread_messages_ids ?? []).filter(id => id !== payload.id),
        });
      });
  }

  onChatClick(chat: UserChat): void {
    if (chat.id == null) {
      this.messageCenterService.createDirectChat({ direct_to: chat.direct_to.id })
        .subscribe({
          next: (response) => this.openChat.emit(response),
          error: (error) => this.notificationsService.showError(error),
        });
    } else {
      this.openChat.emit(chat);
    }
  }

  onChatDeleteClick(event: MouseEvent, chat: UserChat): void {
    event.stopPropagation();

    this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        text: this.translate.instant('messageCenter.deleteChatConfirmation', { name: chat.name })
      }
    }).afterClosed()
      .pipe(filter(result => !!result), untilDestroyed(this))
      .subscribe(() => this.deleteChat(chat));
  }

  private deleteChat(chat: UserChat): void {
    this.messageCenterService.deleteUserChat(chat.id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => this.deleteItem(chat),
        error: (error) => this.notificationsService.showError(error)
      });
  }

  onScroll(element: HTMLElement): void {
    const scroll = getRemainingScrollDistanceToBottom(element);

    if (scroll < 100 && !this.isLoadedAllItems && !this.isLoading) {
      this.pagination.offsetChanged(this.pagination.offset + this.pagination.limit);
      this.responseMergeStrategy = MergeStrategy.Append;
      this.loadItems();
    }
  }

  formatDate(date: string): string {
    return ISOTimeToDaysHoursMinutes(date);
  }
}
