import { Subscription } from 'rxjs';

import { MCProfile } from '../models/user.model';

export function ISOTimeToDaysHoursMinutes(time: string): string {
  const dayNow = new Date();
  const dayMessage = new Date(time);
  dayNow.setHours(0, 0, 0);
  const utcOffset = new Date().getTimezoneOffset();

  function pad(n): string {
    return (n < 10 ? '0' : '') + n;
  }

  if (dayMessage.getTime() >= dayNow.getTime() - utcOffset) {
    return `${ pad(dayMessage.getHours()) }:${ pad(dayMessage.getMinutes()) }`;
  } else {
    return `${ pad(dayMessage.getDate()) }/${ pad(dayMessage.getMonth() + 1) }/${ dayMessage.getFullYear() } ${ pad(dayMessage.getHours()) }:${ pad(dayMessage.getMinutes()) }`;
  }
}

export function getFirstLetterName(name: string): string {
  return name.charAt(0).toUpperCase();
}

export function getFullName(user: MCProfile): string {
  if (!user.first_name && !user.last_name) {
    return '';
  }
  let name = user.first_name;
  if (user.middle_name) {
    name += ` ${ user.middle_name }`;
  }
  name += ` ${ user.last_name }`;
  return name;
}

export function unsubscribe(...subscriptions: Subscription[]): void {
  subscriptions.forEach(
    (subscription, index, array) => {
      if (subscription && subscription.unsubscribe && typeof array[index].unsubscribe === 'function') {
        subscription.unsubscribe();
      }
    }
  );
}
