import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';

import { LocalStorageModule } from 'angular-2-local-storage';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { GlobalConfig, ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import * as WebFont from 'webfontloader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { MessageCenterModule } from '@app/pages/message-center/message-center.module';
import { TokenInterceptor } from '@app/core/interceptors/token.interceptor';
import { Profile } from "@app/core/services/auth/profile.token";
import { AuthService } from "@app/core/services";
import { RouteReuseStrategy } from "@angular/router";
import { RouteNoReuseStrategy } from "@app/core/route-reuse-strategies/no-route-reuse.strategy";


function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

WebFont.load({
  custom: { families: ['Material Icons', 'Material Icons Outlined', 'Material Icons Two Tone', 'Material Icons Round', 'Material Icons Sharp'] }
});

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    PipesModule,
    AngularSvgIconModule.forRoot(),
    LocalStorageModule.forRoot({
      prefix: 'hc-app',
      storageType: 'localStorage'
    }),
    ToastrModule.forRoot({} as GlobalConfig),
    ToastContainerModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MessageCenterModule,
  ],
  providers: [
    { provide: MAT_SELECT_CONFIG, useValue: { overlayPanelClass: 'app-select-overlay' } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: Profile,
      deps: [AuthService],
      useFactory: (auth: AuthService) => auth.profile,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteNoReuseStrategy
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
