import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import { LoadedMessageFile } from '../../../models/message.model';
import { allowedMIMETypes } from '../../constants';
import { toBase64 } from '@app/common';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadComponent {
  @Input() type: 'button' | 'icon' | 'field' = 'button';
  @Input() fieldSize: string;
  @Input() chatFiles: boolean = false;
  @Input() urlImg: string;
  @Input() multiple: boolean = true;
  @Output() filesAdded = new EventEmitter<LoadedMessageFile[]>();
  @ViewChild('fileInput') fileInput: ElementRef;

  loadedFiles: LoadedMessageFile[] = [];
  readonly allowedMIMETypes = allowedMIMETypes;

  async fileUploaded(event: Event): Promise<void> {
    if (!this.multiple) {
      this.removeAvatar(event);
    }
    const files = (event.target as HTMLInputElement).files;

    function setIndex(array): number {
      return array.reduce((a, b) => {
        return a.index > b.index ? a.index : b.index + 1;
      }, 0);
    }

    for (let i = 0; i < files.length; i++) {
      await toBase64(files[i]).then(file => {
        this.loadedFiles.push({
          index: setIndex(this.loadedFiles),
          name: files[i].name,
          file
        });
      });
    }

    this.filesAdded.emit(this.loadedFiles);

    if (this.type === 'field') {
      this.urlImg = this.loadedFiles[0]?.file;
    }

    if (this.chatFiles) {
      this.loadedFiles = [];
    }
  }

  removeAvatar(event): void {
    event.stopPropagation();
    this.urlImg = undefined;
    this.loadedFiles = [];
    this.filesAdded.emit([]);
  }

  openDialogUpload(): void {
    this.fileInput.nativeElement.click();
  }
}
