import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { groupsTypes } from '../shared/constants';
import { UserChat } from '../models/chat.model';
import { MessageCenterService } from '../services';
import { PaginatedResponse } from '../models/paginated-response.model';
import { User } from '../models/user.model';
import { LoadedMessageFile } from '../models/message.model';
import { NotificationsService } from '@app/core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateGroupComponent implements OnInit {
  form: FormGroup;
  isLoading: boolean;
  isSending: boolean;

  readonly groupsTypes = groupsTypes;
  usersList: User[];
  selectedUsers: User[] = [];
  chatId: number;
  loadedAvatar: string;

  allChipsUsers: string[] = [];
  selectedChipsUsers: string[] = [];

  constructor(
    private dialogRef: MatDialogRef<CreateGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private messageCenterService: MessageCenterService,
    private notificationsService: NotificationsService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  get avatar(): FormControl {
    return this.form.get('avatar') as FormControl;
  }

  ngOnInit(): void {
    this.initForm();

    this.chatId = this.data?.chatId;
    if (this.chatId) {
      this.loadExistingGroup(this.chatId);
    } else {
      this.loadUsers();
    }
  }

  private initForm(): void {
    this.form = this.fb.group({
      avatar: null,
      name: [null, [Validators.required, Validators.maxLength(24)]],
      type: [null, Validators.required]
    });
  }

  changeChipsUsers(event): void {
    const user = this.usersList.find(user => user.short_name === event);
    this.selectedUsers.push(user);
  }

  searchUsers(search: string): void {
    if (search && search.length < 2) {
      return;
    }
    this.allChipsUsers = [];
    this.usersList = [];
    this.cdr.detectChanges();
    this.loadUsers(search);
  }

  loadUsers(search?: string): void {
    const params: any = {
      is_widget: true,
      exclude_yourself: 'yes'
    };

    if (search) {
      params.search = search;
      params.lookup_field = 'full_name';
      params.limit = 100;
    } else {
      params.limit = 15;
    }
    this.messageCenterService.getUsersWidget(params)
      .pipe(untilDestroyed(this))
      .subscribe((response: PaginatedResponse<User>) => {
          this.usersList = response.results;
          this.allChipsUsers = response.results.map(user => user.short_name);
          this.cdr.detectChanges();
        },
        (error: HttpErrorResponse) => {
          this.notificationsService.showError(error);
        });
  }

  private loadExistingGroup(chatId: number): void {
    this.isLoading = true;
    this.messageCenterService.getUserChatById(chatId).pipe(
        finalize(() => this.isLoading = false),
        untilDestroyed(this)
      ).subscribe((response: UserChat) => {
          this.loadedAvatar = response.image;
          this.form.patchValue({
            avatar: response.image,
            name: response.name,
            type: this.groupsTypes.find(type => type.value === response.access_type).value
          });
          this.selectedUsers = response.members;
          this.selectedChipsUsers = response.members.map(user => user.short_name);
          this.cdr.markForCheck();
          this.loadUsers();
        },
        (error) => this.notificationsService.showError(error)
      );
  }

  removeChip(event: string): void {
    const idx = this.selectedUsers.findIndex(user => user.short_name === event);
    this.selectedUsers.splice(idx, 1);
  }

  createGroup(): void {
    this.isSending = true;
    const form = this.form.value;
    form.users = [];
    let newMembers = [];
    this.selectedUsers.forEach(user => {
      if (user?.id) {
        form.users.push(user.id);
      } else {
        newMembers.push(user?.meta);
      }
    });

    const payload = {
      image: form.avatar,
      name: form.name,
      access_type: +form.type,
      members: form.users,
      to_create_members: newMembers
    };
    if (this.chatId) {
      if (payload.image === this.loadedAvatar) {
        delete payload.image;
      }
      this.messageCenterService.updateGroupChat(this.chatId, payload).pipe(
        finalize(() => {
          this.isSending = false;
          this.cdr.markForCheck();
        }),
        untilDestroyed(this)
      ).subscribe((response: UserChat) => {
        this.close(response);
      }, error => {
        this.notificationsService.showError(error);
      });
    } else {
      this.messageCenterService.createGroupChat(payload).pipe(
        finalize(() => {
          this.isSending = false;
          this.cdr.markForCheck();
        }),
        untilDestroyed(this)
      ).subscribe((response: UserChat) => {
        this.close(response);
      }, error => {
        this.notificationsService.showError(error);
      });
    }
  }

  addAvatar(files: LoadedMessageFile[]): void {
    let avatar = null;
    if (files.length) {
      avatar = files[0].file;
    }
    this.form.patchValue({ avatar });
  }

  close(chat: UserChat | null = null): void {
    this.dialogRef.close(chat);
  }
}
