import { OfficeWidget, PayerWidget, UserWidget } from '@app/models/widgets.model';
import { PaginationParams } from '@app/shared/interfaces/pagination.class';

export interface Patient {
  id: number;
  number: string;
  avatar: string;
  full_name: string;
  birthday: string;
  phone_number_info: {
    number: string;
  };
  address_info: AddressInfo;
  basic_services_details: BasicServiceDetail[];
  nurse_service_detail: any;
  office_detail: OfficeWidget;
  payers?: string;
  payers_details: PayerWidget[];
  nurse_detail: UserWidget;
  coordinators_details: UserWidget[];
  status_value: string;
}

interface BasicServiceDetail {
  id: number;
  name: string;
  is_nurse: boolean;
}

interface AddressInfo {
  id: number;
  address_detail: AddressDetail;
}

interface AddressDetail {
  id: number;
  full_address: string;
}

export interface PatientAttentions {
  master_week_blasting_count: number;
}

export enum PatientStatus {
  Active,
  Hold,
  Discharged,
  HospitalizedObservation,
  HospitalizedAdmitted,
  PatientNeverStarted
}

export interface PatientsRequestParams extends PaginationParams {
  widget?: string;
  statuses?: PatientStatus[];
  lookup_field?: string;
  search?: string;
  disciplines?: number[];
  payers?: number[];
  coordinators?: number[];
  offices?: number[];
}
